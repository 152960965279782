import React from 'react'
import PropTypes from 'prop-types'
import {If, For, Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import {isEmpty, padStart} from 'lodash'
import {navigate} from 'gatsby'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import DateRangeIcon from '@material-ui/icons/DateRange'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DialogActions from '@material-ui/core/DialogActions'
import Chip from '@material-ui/core/Chip'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook'
import WebIcon from '@material-ui/icons/Language'
import Button from '@material-ui/core/Button/Button'
import CallModal from '../CallModal'


const useStyles = makeStyles({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    fontSize: '1.2rem',
    fontWeight: '700',
  },
  subheader: {
    fontSize: '0.8rem',
    color: '#262E2C',
  },
  icon: {
    fontSize: '1rem',
  },
  link: {
    color: '#272e2c',
  },
  dialogContent: {
    padding: 0,
  },
  content: {
    padding: '0 1.5rem 1rem',
  },
  thumbnailWrapper: {
    position: 'relative',
    width: '100%',
  },
  thumbnail: {
    width: '100%',
  },
  badgeWrapper: {
    position: 'absolute',
    bottom: 0,
    padding: '1rem 1.5rem',
  },
  badge: {
    padding: '0.25rem',
    marginRight: '0.5rem',
    backgroundColor: 'white',
    fontSize: '2rem',
    borderRadius: '50%',
    boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.5)',
  },
  typesWrapper: {
    margin: '1.5rem 0',
  },
  buttonsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1rem',
    height: '1rem',
    color: '#272e2c',
    fontSize: '1.5rem',
    padding: '1rem',
    marginLeft: '0.5rem',
    marginBottom: '0.5rem',
  },
})


const RestaurantDetail = ({
  data: {
    title,
    thumbnail,
    mobileNumber,
    address,
    googleMaps,
    menu,
    special,
    notes,
    packagePrice,
    deliveryPrice,
    minimalPrice,
    isDelivery,
    isDailyMenu,
    dailyMenuLink,
    types,
    openingHours,
    socials: {
      facebookUrl,
      webUrl,
      instagramUrl,
    },
    dailyMenu,
  },
  closeTo,
  ...props
}) => {
  const classes = useStyles({thumbnail})
  const getTime = (day) => {
    const date = new Date(day)
    const hour = padStart(date.getHours(), 2, '0')
    const minutes = padStart(date.getMinutes(), 2, '0')

    return `${hour}:${minutes}`
  }

  const closeModal = () => navigate(closeTo, {state: {noScroll: true}})

  return (

    <Dialog
        open
        onClose={closeModal}
        width="600px"
        scroll="paper"
        aria-labelledby="Detail reštaurácie"
        aria-describedby="Detail reštaurácie"
    >
      <DialogTitle>
        <div className={classes.titleWrapper}>
          <div>
            <strong>{title}</strong> <br />
            <Choose>
              <When condition={!isEmpty(googleMaps)}>
                <a href={googleMaps} target="_blank" rel="noreferrer" className={classes.subheader}>
                  <Typography>
                    {address}
                  </Typography>
                </a>
              </When>
              <Otherwise>
                <Typography>
                  {address}
                </Typography>
              </Otherwise>
            </Choose>
          </div>
          <div className={classes.buttonsWrapper}>
            <If condition={!isEmpty(webUrl)}>
              <a className={classes.actionButton} href={webUrl} target="_blank" rel="noreferrer noopener">
                <Typography variant="srOnly">Webstránka</Typography>
                <WebIcon />
              </a>
            </If>
            <If condition={!isEmpty(facebookUrl)}>
              <a className={classes.actionButton} href={facebookUrl} target="_blank" rel="noreferrer noopener">
                <Typography variant="srOnly">Facebook</Typography>
                <FacebookIcon />
              </a>
            </If>
            <If condition={!isEmpty(instagramUrl)}>
              <a className={classes.actionButton} href={instagramUrl} target="_blank" rel="noreferrer noopener">
                <Typography variant="srOnly">Instagram</Typography>
                <InstagramIcon />
              </a>
            </If>
            <If condition={!isEmpty(mobileNumber)}>
              <CallModal title={title} mobileNumber={mobileNumber} />
            </If>
          </div>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent} align="justify" >
        <If condition={!isEmpty(thumbnail)}>
          <div className={classes.thumbnailWrapper}>
            <img className={classes.thumbnail} src={thumbnail} alt={title} />
            <div className={classes.badgeWrapper}>
              <If condition={isDelivery}>
                <DirectionsCarIcon className={classes.badge} />
              </If>
              <If condition={isDailyMenu}>
                <DateRangeIcon className={classes.badge} />
              </If>
            </div>
          </div>
        </If>
        <div className={classes.content}>
          <div className={classes.typesWrapper}>
            <For each="type" of={types}>
              <Chip className={classes.chip} key={type} label={type} variant="outlined" />
            </For>
          </div>
          <If condition={!isEmpty(mobileNumber)}>
            <Typography
                className={classes.heading}
                variant="h4"
                gutterBottom
            >
              Telefónne číslo:&nbsp;
              <Typography display="inline" gutterBottom>
                {mobileNumber}
              </Typography>
            </Typography>
          </If>
          <Grid container wrap="wrap" spacing={4}>
            <If condition={isDailyMenu}>
              <Grid item>
                <Typography className={classes.heading} variant="h4" gutterBottom>Denné menu</Typography>
                <table>
                  <tbody>
                    <tr>
                      <td width="100"><Typography>Pondelok:</Typography></td>
                      <td>
                        <Typography>
                          {getTime(dailyMenu.monday.from)}
                          &nbsp;–&nbsp;
                          {dailyMenu.monday.toSold ? 'Do vypredania' : getTime(dailyMenu.monday.to)}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td width="100"><Typography>Utorok:</Typography></td>
                      <td>
                        <Typography>
                          {getTime(dailyMenu.tuesday.from)}
                          &nbsp;–&nbsp;
                          {dailyMenu.tuesday.toSold ? 'Do vypredania' : getTime(dailyMenu.tuesday.to)}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td width="100"><Typography>Streda:</Typography></td>
                      <td>
                        <Typography>
                          {getTime(dailyMenu.wednesday.from)}
                          &nbsp;–&nbsp;
                          {dailyMenu.wednesday.toSold ? 'Do vypredania' : getTime(dailyMenu.wednesday.to)}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td width="100"><Typography>Štvrtok:</Typography></td>
                      <td>
                        <Typography>
                          {getTime(dailyMenu.thursday.from)}
                          &nbsp;–&nbsp;
                          {dailyMenu.thursday.toSold ? 'Do vypredania' : getTime(dailyMenu.thursday.to)}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td width="100"><Typography>Piatok:</Typography></td>
                      <td>
                        <Typography>
                          {getTime(dailyMenu.friday.from)}
                          &nbsp;–&nbsp;
                          {dailyMenu.friday.toSold ? 'Do vypredania' : getTime(dailyMenu.friday.to)}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <Choose>
                        <When condition={dailyMenu.isSaturdayOpen}>
                          <td width="100"><Typography>Sobota:</Typography></td>
                          <td>
                            <Typography>
                              {getTime(dailyMenu.saturday.from)}
                              &nbsp;–&nbsp;
                              {dailyMenu.saturday.toSold ? 'Do vypredania' : getTime(dailyMenu.saturday.to)}
                            </Typography>
                          </td>
                        </When>
                        <Otherwise>
                          <td>
                            <Typography>
                              Sobota:
                            </Typography>
                          </td>
                          <td>
                            <Typography>
                              <i>Nie je</i>
                            </Typography>
                          </td>
                        </Otherwise>
                      </Choose>
                    </tr>
                    <tr>
                      <Choose>
                        <When condition={dailyMenu.isSundayOpen}>
                          <td width="100"><Typography gutterBottom>Nedeľa:</Typography></td>
                          <td>
                            <Typography gutterBottom>
                              {getTime(dailyMenu.sunday.from)}
                              &nbsp;–&nbsp;
                              {dailyMenu.sunday.toSold ? 'Do vypredania' : getTime(dailyMenu.sunday.to)}
                            </Typography>
                          </td>
                        </When>
                        <Otherwise>
                          <td>
                            <Typography gutterBottom>
                              Nedeľa:
                            </Typography>
                          </td>
                          <td>
                            <Typography gutterBottom>
                              <i>Nie je</i>
                            </Typography>
                          </td>
                        </Otherwise>
                      </Choose>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </If>
            <Grid item>
              <Typography className={classes.heading} variant="h4" gutterBottom>Otváracie hodiny</Typography>
              <table>
                <tbody>
                  <tr>
                    <td width="100"><Typography>Pondelok:</Typography></td>
                    <td>
                      <Typography>
                        {getTime(openingHours.monday.from)} – {getTime(openingHours.monday.to)}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td width="100"><Typography>Utorok:</Typography></td>
                    <td>
                      <Typography>
                        {getTime(openingHours.tuesday.from)} – {getTime(openingHours.tuesday.to)}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td width="100"><Typography>Streda:</Typography></td>
                    <td>
                      <Typography>
                        {getTime(openingHours.wednesday.from)} – {getTime(openingHours.wednesday.to)}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td width="100"><Typography>Štvrtok:</Typography></td>
                    <td>
                      <Typography>
                        {getTime(openingHours.thursday.from)} – {getTime(openingHours.thursday.to)}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td width="100"><Typography>Piatok:</Typography></td>
                    <td>
                      <Typography>
                        {getTime(openingHours.friday.from)} – {getTime(openingHours.friday.to)}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <Choose>
                      <When condition={openingHours.isSaturdayOpen}>
                        <td width="100"><Typography>Sobota:</Typography></td>
                        <td>
                          <Typography>
                            {getTime(openingHours.saturday.from)} – {getTime(openingHours.saturday.to)}
                          </Typography>
                        </td>
                      </When>
                      <Otherwise>
                        <td>
                          <Typography>
                            Sobota:
                          </Typography>
                        </td>
                        <td>
                          <Typography>
                            <i>Zatvorené</i>
                          </Typography>
                        </td>
                      </Otherwise>
                    </Choose>
                  </tr>
                  <tr>
                    <Choose>
                      <When condition={openingHours.isSundayOpen}>
                        <td width="100"><Typography gutterBottom>Nedeľa:</Typography></td>
                        <td>
                          <Typography gutterBottom>
                            {getTime(openingHours.sunday.from)} – {getTime(openingHours.sunday.to)}
                          </Typography>
                        </td>
                      </When>
                      <Otherwise>
                        <td>
                          <Typography gutterBottom>
                            Nedeľa:
                          </Typography>
                        </td>
                        <td>
                          <Typography gutterBottom>
                            <i>Zatvorené</i>
                          </Typography>
                        </td>
                      </Otherwise>
                    </Choose>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <If condition={!isEmpty(special)}>
              <Grid item>
                <Typography className={classes.heading} variant="h4" gutterBottom>Špeciálna ponuka</Typography>
                <Typography gutterBottom>
                  {special}
                </Typography>
              </Grid>
            </If>
            <If condition={!isEmpty(notes)}>
              <Grid item>
                <Typography
                    className={classes.heading}
                    variant="h4"
                    gutterBottom
                >
                  Poznámky
                </Typography>
                <Typography gutterBottom>
                  {notes}
                </Typography>
              </Grid>
            </If>
            <If condition={isDelivery}>
              <Grid item>
                <Typography className={classes.heading} variant="h4" gutterBottom>Donáška</Typography>
                <Typography>Cena balenia: {packagePrice}</Typography>
                <Typography>Cena donášky: {deliveryPrice}</Typography>
                <Typography gutterBottom>Minimálna cena: {minimalPrice}</Typography>
              </Grid>
            </If>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <If condition={!isEmpty(menu)}>
          <Button
              href={menu}
              target="_blank"
              rel="noreferrer noopener"
              variant="outlined"
          >
            Menu
          </Button>
        </If>
        <If condition={isDailyMenu}>
          <Button
              href={dailyMenuLink}
              target="_blank"
              rel="noreferrer noopener"
              startIcon={<DateRangeIcon />}
              variant="outlined"
              disabled={isEmpty(dailyMenuLink)}
          >
            Denné menu
          </Button>
        </If>
        <Button variant="outlined" onClick={closeModal}>
          Zavrieť
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RestaurantDetail.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    thumbnail: PropTypes.string,
    mobileNumber: PropTypes.string,
    address: PropTypes.string,
    googleMaps: PropTypes.string,
    menu: PropTypes.string,
    special: PropTypes.string,
    notes: PropTypes.string,
    packagePrice: PropTypes.string,
    deliveryPrice: PropTypes.string,
    minimalPrice: PropTypes.string,
    isDelivery: PropTypes.bool,
    isDailyMenu: PropTypes.bool,
    dailyMenuLink: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
    openingHours: PropTypes.object,
    socials: PropTypes.shape({
      facebookUrl: PropTypes.string,
      webUrl: PropTypes.string,
      instagramUrl: PropTypes.string,
    }),
    dailyMenu: PropTypes.object,
  }),
  closeTo: PropTypes.string,
}

export default RestaurantDetail
