import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Seo from '../components/seo'
import Layout from '../components/Layout'
import RestaurantDetail from '../components/Restaurants/RestaurantDetail'


export const data = graphql`
  query($id: String!) {
    markdownRemark(id: {eq: $id}) {
      id
      frontmatter {
        ...Restaurant
      }
    }
  } 
`

const TestTemplate = ({data: {markdownRemark: {id, frontmatter}}, ...props}) => {
  const restaurant = {id, ...frontmatter}

  return (
    <>
      <Seo title={restaurant.title} />
      <Layout isModal>
        <RestaurantDetail data={restaurant} closeTo="/" />
      </Layout>
    </>
  )
}

TestTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TestTemplate
